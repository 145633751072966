<!-- 报名记录 -->
<template>
    <div class="signRecord">
        <div class="Record">
            报名记录(准考证)
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0||scope.row.status==''"></span>
                    <span v-else-if="scope.row.status==1">草稿</span>
                    <span v-else-if="scope.row.status==2">报名</span>
                    <span v-else-if="scope.row.status==3">准考</span>
                </template>
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="course" label="科目" align="center" width="120">
            </el-table-column>
            <el-table-column prop="original_level" label="原等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_level" label="报考等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="price1" label="应缴考试费" align="center" width="120">
            </el-table-column>

            <!-- <el-table-column prop="" label="机构名称" align="center" width="120">
            </el-table-column>
            <el-table-column prop="name" label="考生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="指导教师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="联系电话" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="考级报名费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="副证费用" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="考试场次" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="备注" align="center" width="200">
            </el-table-column> -->

            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                        @click="details(scope.$index, scope.row)">
                        详情
                    </el-button>
                    <!-- <el-button type="danger" icon="el-icon-delete" size="mini" @click="close(scope.$index, scope.row)"
                        v-if="scope.row.status==1">
                        删除
                    </el-button> -->
                </template>
            </el-table-column>
        </el-table>

        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <el-divider></el-divider>

        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                idcard: "", //身份证
                currentPage: 1,
                currentLimit: 10,
                total: 0,
            }
        },
        created() {            
            this.$request({
                url: '/api/studentexam/info',
                method: 'POST',
                data: {
                    uid: localStorage.getItem('token')
                }
            }).then(res => {
                console.log(res.data.idcard)
                if (res.code == 1) {
                    this.idcard = res.data.idcard
                    this.getList()
                }
            })
            
        },
        activated() {
            this.getList()
            // console.log("页面缓存")
        },
        methods: {
            // uid: localStorage.getItem('token')
            getList() {
                this.$request({
                    url: '/api/studentexam/signupList',
                    method: 'POST',
                    data: {
                        uid: localStorage.getItem('token'),
                        idcard: this.idcard
                    }
                }).then(res => {
                    console.log(res,'res')
                    if(res.code==1){
                        this.tableData = res.data
                    }
                    
                })
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            details(index, row) { //详情
                // console.log(row.status)
                if (row.status == 1) { //草稿
                    this.$router.push({
                        path: "/signDraft",
                        query: {
                            id: row.id
                        }
                    })
                } else if (row.status == 2) { //报名
                    this.$router.push({
                        path: "/signState",
                        query: {
                            id: row.id
                        }
                    })
                } else if (row.status == 3) { //准考
                    this.$router.push({
                        path: "/signGrant",
                        query: {
                            id: row.id
                        }
                    })
                }
            },
            close(index, row) { //删除
                console.log(row.id)
                this.$confirm('此操作将永久删除该数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    acncelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/studentexam/signupDel',
                        method: 'POST',
                        data: {
                            uid: localStorage.getItem('token'),
                            signup_id: row.id
                        }
                    }).then(res => {
                        console.log(res)
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                        } else {
                            this.$message({
                                message: "删除失败",
                                type: 'error'
                            });
                        }
                    })
                })


            }
        }
    }
</script>

<style scoped="scoped">
    .signRecord {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .Record {
        padding: 25px;
        font-weight: bold;
        font-size: 16px;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }

    /* 文字 */
    .notice {
        padding-left: 25px;
        padding-top: 38px;
    }

    /* 分页 */
    .el-pagination {
        margin: 0px 20px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }

    /* 底部 */
    .buttom {
        height: 20px;
        line-height: 20px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
